import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Welcome from "../components/display/Welcome_custom";
import FullStore from "../components/display/FullStore_custom";
import Closed from "../components/display/Closed_custom";
import WelcomeCounter from "../components/display/WelcomeCounter_custom";
import * as CommonApi from '../utils/common-api'
import queryString from 'query-string';
import { withRouter } from "react-router";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

class DisplayPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_display: "welcome_counter",
      counter: 0,
      template: "",
      regionName: ""
    };
  }


  refreshData() {
    (async () => {
      //GET DISPLAY
      try {
        var display_id = 0
        let params = queryString.parse(this.props.location.search)
        if (params.display_id !== "") {

          display_id = Number(params.display_id)

        } else {
          console.log("A problem has occurred")
        }


        const requestDisplay = {
          ...CommonApi.getRequestOptions(),
          method: 'GET',
        };
        var responseDisplay = await fetch(CommonApi.getBaseUrlPublicApi() + "/display-dashboard?display_id=" + display_id, requestDisplay)

        const dataDisplay = await responseDisplay.json();
        //SET STATE TEMPLATE
        this.setState({
          template: dataDisplay.template
        });
        if (!responseDisplay.ok) {
          if (responseDisplay.status === 403) {
            console.log("A problem has occurred")
          } else {
            console.log("A problem has occurred")
          }
        } else {
          //OK
          if (dataDisplay !== null) {
            console.log(dataDisplay)

            this.setState({ regionName: dataDisplay.region_name })

            if (dataDisplay.open === true && dataDisplay.counter_enabled === true && dataDisplay.full === false) {
              // Welcome with counter
              this.setState({ type_display: "welcome_counter", counter: dataDisplay.counter })
            } else if (dataDisplay.open === true && dataDisplay.counter_enabled === false && dataDisplay.full === false) {
              // Welcome without counter
              this.setState({ type_display: "welcome" })
            }
            else if (dataDisplay.open === true && dataDisplay.full === true) {
              // Full
              this.setState({ type_display: "full_store" })
            }
            else if (dataDisplay.open === false) {
              // Closed
              this.setState({ type_display: "closed" })
            }
            this.setState({ switchDisplay: dataDisplay.display_on, switchMonitorPeople: dataDisplay.display_counter, alertLevel: dataDisplay.alert_capacity, maxCapacity: dataDisplay.max_capacity, emailDisabled: !dataDisplay.email_alert_on, email: dataDisplay.email_alert, switchAlert: dataDisplay.email_alert_on });
          }
        }
      } catch (error) {
        console.log("A problem has occurred")
      }
    })();
  }


  componentDidMount() {
    //FIRST CALL
    this.refreshData();

    //START TIMER 1 MINUTES
    this.intervalId = setInterval(this.timer.bind(this), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  timer() {
    this.refreshData();
  }


  render() {
    return (
      <div>
        {this.state.type_display === 'welcome_counter' && (
          <WelcomeCounter template={this.state.template} counter={this.state.counter} regionName={this.state.regionName} />
        )}
        {this.state.type_display === 'closed' && (
          <Closed template={this.state.template} regionName={this.state.regionName} />
        )}
        {this.state.type_display === 'full_store' && (
          <FullStore image_full_store={this.state.template.image_full_store} regionName={this.state.regionName} />
        )}
        {this.state.type_display === 'welcome' && (
          <Welcome template={this.state.template} regionName={this.state.regionName} />
        )}
      </div>
    );
  }
}

// <Closed/>
//<FullStore/>
// <Welcome/>

export default withStyles(styles)(withRouter(DisplayPage));
